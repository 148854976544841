(function ($) {
	$.fn.isInView = function () {
		if (this.length === 0) {
			return false;
		}
		var elementTop = this.offset().top;
		var elementBottom = elementTop + this.outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	$.fn.isInPercentView = function (percentage) {
		if (this.length === 0) {
			return false;
		}
		var elementTop = this.offset().top;
		var elementBottom = elementTop + this.outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height() * (1 - percentage);

		return elementBottom > viewportTop && elementTop < viewportBottom;
	};
})(jQuery);

let headerHeight;

$(document).ready(() => {
	const clonedHeader = $("header").clone();
	clonedHeader.addClass("shrink");
	$("body").append(clonedHeader);
	headerHeight = clonedHeader.height();
	clonedHeader.remove();

	$("header .contacts").height($("header .contacts").height());

	$("#header-spacer").width("100vw").height($("header").height());

	$("#mast .hidden").each(function (index) {
		setTimeout(() => {
			$(this).removeClass("hidden");
		}, index * 150);
	});

	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	let vh = window.innerHeight * 0.01;

	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty("--vh", `${vh}px`);

	$(window).scroll(onScroll);

	// Trigger the scroll event immediately to account for the page loading with an initial scroll amount.
	onScroll();

	function onScroll() {
		// Shrink Header
		const scrollTop = $(window).scrollTop();

		if (scrollTop > 50) {
			$("header").addClass("shrink");
		} else {
			$("header").removeClass("shrink");
		}

		if ($("#mast-head").isInView()) {
			$("#mast-head .hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if ($("#about").isInPercentView(0.25)) {
			$("#about .hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if ($("#about-2").isInPercentView(0.25)) {
			$("#about-2 .hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if ($("#about-3").isInPercentView(0.25)) {
			$("#about-3 .hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if ($("#advantages").isInPercentView(0.35)) {
			$("#advantages .hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		if ($("#work").isInPercentView(0.35)) {
			$("#work h2.hidden").each(function (index) {
				setTimeout(() => {
					$(this).removeClass("hidden");
				}, index * 150);
			});
		}

		$("#work .item.hidden[data-shown!='true']").each(function (index) {
			const element = $(this);
			if (element.isInPercentView(0.35)) {
				element.attr("data-shown", "true");
				setTimeout(() => {
					element.removeClass("hidden");
				}, index * 150);
			}
		});
	}

	// Wire scroll function to fragment links.
	$("a[href^='#'], a[href^='/#']")
		.filter(function () {
			const linkPath = this.href.match(/^[^#]*/)[0];
			const currentPath = window.location.origin + window.location.pathname;

			return linkPath === currentPath;
		})
		.on("click", function (event) {
			if (this.hash !== "") {
				event.preventDefault();

				var hash = this.hash;

				scrollTo($(hash));

				$("#nav-options").css("height", "");
			}
		});

	// Wire mobile navigation menu.
	$("#mobile-nav-button").click((e) => {
		$("#mobile-nav").toggleClass("hidden");
	});

	$("#mobile-nav a").click((e) => {
		$("#mobile-nav").addClass("hidden");
	});

	// Form submission
	const SUBMIT_TIMEOUT = 5 * 1000;
	let lastSubmit = 0;
	let submitting = false;

	$("#contact-submit").click((e) => {
		e.preventDefault();

		if (submitting) {
			return;
		}

		// Debounce logic.
		const currentTime = new Date().getTime();
		if (currentTime - lastSubmit < SUBMIT_TIMEOUT) {
			return;
		}
		lastSubmit = currentTime;

		// Reset any current errors.
		$(".input").removeClass("error");

		let formValid = true;

		const name = $("#name").val();
		const email = $("#email").val();
		const message = $("#message").val();
		function validate(value, input, regex) {
			const valid = regex.test(value);
			if (!valid) {
				formValid = false;

				setTimeout(() => {
					input.addClass("error");
				}, 1);
			}
		}

		validate(name, $("#name"), /\S/);
		validate(email, $("#email"), /\S+@\S+\.\S+/);
		validate(message, $("#message"), /\S/);

		if (formValid) {
			$("#contact-submit").addClass("loading");
			const data = {
				name,
				email,
				message,
			};

			submitting = true;

			const xhr = new XMLHttpRequest();
			xhr.open("POST", "/contact.php", true);
			xhr.setRequestHeader("Content-type", "application/json");
			xhr.send(JSON.stringify(data));
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4 && xhr.status === 200) {
					$("#contact-submit").removeClass("loading").addClass("submitted");
					$("#contact-submit .text").text("Submitted!");
				}
			};
		}
	});

	// Remove error indicator when an input is clicked on.
	$(".input").click(function () {
		setTimeout(() => {
			$(this).removeClass("error");
		}, 200);
	});
});

function scrollTo(section, immediate) {
	if (immediate) {
		$("html, body").scrollTop(section.offset().top) - headerHeight;
	} else {
		$("html, body").animate(
			{
				scrollTop: section.offset().top - headerHeight,
			},
			800
		);
	}
}
